import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        <div className="space-y-6">
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              1. Information We Collect
            </h2>
            <p>
              We may collect various types of personal information from you when
              you use our app, including but not limited to:
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>Name, email address, phone number, and billing address</li>
              <li>Payment information (such as credit card details)</li>
              <li>Information about your booking history and preferences</li>
              <li>
                Demographic information (such as age, gender, and interests)
              </li>
              <li>
                Information about your device and internet usage (such as IP
                address, browser type, and operating system)
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">
              2. How We Use Your Information
            </h2>
            <p>
              We may use your personal information for various purposes,
              including but not limited to:
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>Processing and fulfilling your movie bookings</li>
              <li>
                Communicating with you about your bookings, promotions, and
                updates
              </li>
              <li>Personalizing your movie recommendations</li>
              <li>Improving our app and services</li>
              <li>Preventing fraud and unauthorized transactions</li>
              <li>
                Complying with legal requirements and protecting our rights
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">
              3. How We Share Your Information
            </h2>
            <p>
              We may share your personal information with third parties for
              various purposes, including but not limited to:
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>Payment processors and fraud prevention services</li>
              <li>Marketing and advertising partners</li>
              <li>Analytics and website hosting providers</li>
              <li>Legal and regulatory authorities</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">
              4. How We Protect Your Information
            </h2>
            <p>
              We take reasonable measures to protect your personal information
              from unauthorized access, use, or disclosure. We use
              industry-standard security technologies and procedures to
              safeguard your information.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">
              5. Your Rights and Choices
            </h2>
            <p>
              You have certain rights and choices regarding your personal
              information, including but not limited to:
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>Accessing and updating your personal information</li>
              <li>Opting out of marketing communications</li>
              <li>Deleting your personal information</li>
              <li>Objecting to certain uses of your personal information</li>
              <li>
                Withdrawing your consent for certain uses of your personal
                information
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">6. Cookies</h2>
            <p>
              Our web app does not use any cookies to collect or store
              information. No tracking or session cookies are utilized during
              your interaction with the app.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">
              7. Children's Privacy
            </h2>
            <p>
              Our app is not intended for children under the age of 13. We do
              not knowingly collect personal information from children under the
              age of 13.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">
              8. Changes to this Privacy Policy
            </h2>
            <p>
              We may update this privacy policy from time to time to reflect
              changes in our information practices. If we make any material
              changes, we will notify you by email or by posting a notice on our
              app.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
            <p>
              If you have any questions or concerns about this privacy policy,
              please contact us via email.
            </p>
            <p className="mt-2">GST: 32AAXFB7869H1ZS (OWN BY - BIG PICTURES)</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Jurisdiction</h2>
            <p>
              Each party irrevocably agrees that the courts of Surat shall have
              exclusive jurisdiction to settle any dispute or claim (including
              non-contractual disputes or claims) arising out of or in
              connection with this privacy policy or its subject matter.
            </p>
          </section>
        </div>
        <div className="mt-8 text-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800">
            Back to Home
          </Link>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
